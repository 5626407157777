"use client";

import DevAlert from "@/app/[locale]/dashboard/[COMPANY_ID]/components/layout/DevAlert";
import React, { useEffect } from "react";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.appV3 = true;
    }
  }, [typeof window]);

  return (
    <div>
      <div
        id="pro-layout"
        style={{
          height: "100vh",
          overflow: "auto",
        }}
        className="app">
        {children}
      </div>
      <DevAlert />
    </div>
  );
}
